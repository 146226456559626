<template>
  <section class="almacen-etapas container-fluid overflow-auto custom-scroll mt-2">
      <titulo-divisor titulo="Lista de etapas generales" class="mx-0">
          <div class="row">
              <div class="col pl-1">
                <search :data-to-search="filtered_stages" :auto-search="false" :prop="['nombre']" @filtered="refreshStages" placeholder="Buscar material en la lista"/>
              </div>
              <div class="col-auto pl-1">
                <el-popover v-model="visibleCrearEtapa" placement="bottom-end" width="360" trigger="click" class="px-0" :disabled="!permitAction('almacen.etapas.crear')">
                    <div class="row mx-0 justify-content-center f-600 text-general">
                        Crear etapa
                    </div>
                    <hr />
                    <ValidationObserver ref="validator">
                        <div class="row mx-0 justify-content-center my-2">
                            <div class="col-10 my-2">
                                <ValidationProvider v-slot="{ errors }" name="nombre" rules="required|max:255">
                                    <p class="input-label-top">Nombre de la etapa <required/></p>
                                    <el-input v-model="model.nombre" class="f-12 br-5 w-100" size="small" :maxlength="255" show-word-limit />
                                    <vee-error :text="errors[0]"/>
                                </ValidationProvider>
                            </div>
                        </div>
                    </ValidationObserver>
                    <hr />
                    <div class="text-right m-0">
                        <button class="btn btn-cerrar f-12" @click="visibleCrearEtapa = !visibleCrearEtapa">Cerrar</button>
                        <button class="btn btn-crear f-12 ml-2" @click="createStage">Guardar</button>
                    </div>
                    <button slot="reference" class="btn btn-general f-12 px-4"
                        :class="permitAction('almacen.etapas.crear') ? 'cr-pointer' : 'cr-not-allowed'" 
                        @click="functionPermitAction('almacen.etapas.crear')"
                    >Crear etapa</button>
                </el-popover>
              </div>
          </div>
      </titulo-divisor>
      <div v-for="(data,i) in filtered_stages" :key="i" class="row mx-0 mb-3">
          <div class="col-10" @click="verEtapa(data.id)">
              <card-phase 
                :name="data.nombre" 
                :cantConf="data.configuraciones" 
                :minHours="data.horas_minimo" 
                :maxHours="data.horas_maximo"
                :minValue="data.valor_minimo"
                :maxValue="data.valor_maximo"
                />
          </div>
      </div>
      <default-image 
      v-if="filtered_stages.length === 0"
      image="/img/default/no-steps.svg"
      text="No se han creado etapas"
      />
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import cardPhase from '../components/cardPhase.vue'
export default {
    components:{
        cardPhase,
    },
    data(){
        return{
           inputBuscar:'',
           visibleCrearEtapa:false,
           cambiarNombre:'',
           model: {
               nombre: ''
           },
           filtered_stages: [],
           stagesDefault: []
        }
    },
    computed:{
        ...mapGetters({
			user: 'auth/user',
            stagesList: 'almacen/stages/stages'
        }),
    },
    watch:{
        stagesList(val){
            this.filtered_stages = val
        }
    },
    async created(){
        this.getBreadcumbs([ 'almacen.main', 'almacen.etapas' ]);
        await this.$store.dispatch('almacen/stages/Action_get_stages');
        this.stagesDefault = this.stagesList
        this.filtered_stages = this.stagesList
    },
    methods:{
        ...mapActions({
            getBreadcumbs: 'navigation/breadcumbs/getBreadcumbs',
        }),
        verEtapa(idEtapa){
            this.$router.push({ name: 'almacen.etapas.ver', params: { id: idEtapa} })
        },
        async createStage(){
            let valid = await this.$refs.validator.validate();
            if (valid) {
                // create stage
                let obj = { nombre: this.model.nombre }
                const res = await this.$store.dispatch('almacen/stages/Action_create_stage', obj)

                if(res?.id) this.$router.push({ name: 'almacen.etapas.ver', params: { id: res.id }});

                this.visibleCrearEtapa = !this.visibleCrearEtapa
            }
        },
        refreshStages(filtered){
            if(filtered.length){
                this.filtered_stages = filtered
                this.$notify({title: `Se han encontrado ${filtered.length} resultados`, type:'success'})
            } else {
                this.filtered_stages = this.stagesDefault
                this.$notify({title: `No se han encontrado resultados`, type:'info'})
            }
        }
    }

}
</script>

<style lang="scss" scoped>
.almacen-etapas{
    height: calc(100vh - 240px);
}

.default-image{
    height: calc(100vh - 325px);
    & > img{
        max-height: 35vh;
        max-width: 50vh;
        min-width: 30vh;
    }
    & > span{
        font-size: 1.3em;
    }
}
</style>