<template>
    <div class="card-etapa-general br-4 p-2 f-14 cr-pointer">
        <div class="row">
            <div class="col-12 col-md-6 col-lg-5 my-auto tres-puntos">
                <el-tooltip :content="name" placement="top-start">
                    <p class="tres-puntos"> {{ name }} </p>
                </el-tooltip>
            </div>
            <div class="col-auto col-md-6 col-lg-1">
                <el-tooltip content="Número de configuraciones" effect="light" visible-arrow>
                    <div class="d-flex">
                        <i class="icon-format-list-bulleted-type f-18" />
                        <p class="my-auto"> {{ cantConf }} </p>
                    </div>
                </el-tooltip>
            </div>
            <div class="col-12 col-md-6 col-lg-auto">
                <div class="d-flex">
                    <i class="icon-cash-multiple f-18" />
                    <p class="my-auto">{{ formatoMoneda(minValue) }} - {{  formatoMoneda(maxValue) }} </p>
                </div>
            </div>
            <div class="col-12 col-md-6 col-lg-3 col-xl">
                <div class="d-flex">
                    <i class="icon-account-clock f-18" />
                    <p class="my-auto"> {{ minHours }} - {{ maxHours }} Horas</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        name:{
            type: String,
        },
        cantConf:{
            type: Number,
        },
        minValue:{
            type: String,
        },
        maxValue:{
            type: String,
        },
        minHours:{
            type: Number,
        },
        maxHours:{
            type: Number,
        },
    }

}
</script>
<style lang="scss" scoped>
.card-etapa-general{
    background: #F9F9F9;
    border: 1px solid #DDDDDD;
}
</style>